var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search search-container" },
    [
      _c("category-block", {
        staticClass: "category-block category-block-1",
        attrs: { target: _vm.category, position: "position1" }
      }),
      _c("v-container", [
        _c("div", { staticClass: "mb-3 mb-sm-0" }, [
          _c("h4", { staticClass: "text-uppercase" }, [
            _vm._v(" Risultati per: Magazine ")
          ]),
          _vm.count > 0
            ? _c("strong", { staticClass: "text-body-1" }, [
                _vm._v(" " + _vm._s(_vm.count) + " articoli trovati per "),
                _vm.query
                  ? _c("span", { staticClass: "highlight" }, [
                      _vm._v('"' + _vm._s(_vm.query) + '"')
                    ])
                  : _vm._e(),
                _vm.barcode
                  ? _c("span", { staticClass: "highlight" }, [
                      _vm._v(' code "' + _vm._s(_vm.barcode) + '"')
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ]),
        _vm.realSearch
          ? _c("p", { staticClass: "subtitle-2" }, [
              _vm._v(" Avevi cercato "),
              _c("a", { staticClass: "real_search" }, [
                _vm._v(_vm._s(_vm.realSearch))
              ]),
              _vm._v(" ma non ha risultati. ")
            ])
          : _vm._e()
      ]),
      _c(
        "v-container",
        [
          _c("ProductListGrid", {
            key: _vm.key,
            attrs: {
              query: _vm.query,
              barcode: _vm.barcode,
              parentCategoryId: _vm.parent_category_id,
              mode: "search",
              hideFilterString: "Main Term"
            },
            on: {
              productsCount: _vm.updateCount,
              searchedText: _vm.updateRealSearch
            }
          })
        ],
        1
      ),
      _c("category-block", {
        staticClass: "category-block category-block-2",
        attrs: { target: _vm.category, position: "position2" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }